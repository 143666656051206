import React from 'react'
import {ReactComponent as TwitterLogo} from '../social-icons/twitter.svg'
import {ReactComponent as InstagramLogo} from '../social-icons/instagram.svg'
// import {ReactComponent as TumblrLogo} from '../social-icons/tumblr.svg'
// import {ReactComponent as TiktokLogo} from '../social-icons/tiktok.svg'
// import {ReactComponent as YoutubeLogo} from '../social-icons/youtube.svg'
// import {ReactComponent as PatreonLogo} from '../social-icons/patreon.svg'
import {ReactComponent as KofiLogo} from '../social-icons/ko-fi.svg'
// import {ReactComponent as FuraffinityLogo} from '../social-icons/furaffinity.svg'
import {ReactComponent as EmailLogo} from '../social-icons/email.svg'
import {ReactComponent as TelegramLogo} from '../social-icons/telegram.svg'

export default function SocialIcons() {
  return (
    <div style={{display:'flex', flexFlow:'row wrap', justifyContent:'space-between', marginTop:'10px'}}>
        <a href='https://twitter.com/huntysdoodles' target="_blank" rel="noopener noreferrer"><div className='social-icons' style={{marginLeft:'0px'}}><TwitterLogo className='svg-social-icon'/></div></a>
        <a href='https://instagram.com/huntysdoodles' target="_blank" rel="noopener noreferrer"><div className='social-icons'><InstagramLogo className='svg-social-icon'/></div></a>
        {/* <a href='https://tumblr.com/huntysdoodles/' target="_blank" rel="noopener noreferrer"><div className='social-icons'><TumblrLogo className='svg-social-icon'/></div></a> */}
        {/* <a href='https://vm.tiktok.com/ZMJmST5N5/' target="_blank" rel="noopener noreferrer"><div className='social-icons'><TiktokLogo className='svg-social-icon' style={{width:'30px',height:'30px'}} /></div></a> */}
        {/* <a href='https://www.youtube.com/channel/UCxp8GJAReLJDEfn4N2f_ulw' target="_blank" rel="noopener noreferrer"><div className='social-icons'><YoutubeLogo className='svg-social-icon'/></div></a> */}
        {/* <a href='https://www.patreon.com/huntysdoodles' target="_blank" rel="noopener noreferrer"><div className='social-icons'><PatreonLogo className='svg-social-icon'/></div></a> */}
        <a href='https://ko-fi.com/huntysdoodles' target="_blank" rel="noopener noreferrer"><div className='social-icons' style={{fontSize:'26px'}}><KofiLogo className='svg-social-icon' style={{width:'34px',height:'28px'}}/></div></a>
        {/* <a href='https://www.furaffinity.net/user/huntysdoodles/' target="_blank" rel="noopener noreferrer"><div className='social-icons' style={{fontSize:'26px'}}><FuraffinityLogo className='svg-social-icon' style={{width:'25px',height:'25px'}}/></div></a> */}
        <a href='mailto:huntysdoodles@gmail.com' target="_blank" rel="noopener noreferrer"><div className='social-icons' style={{fontSize:'26px'}}><EmailLogo className='svg-social-icon' style={{width:'22px',height:'23px'}}/></div></a>
        <a href='https://t.me/huntysdoodles' target="_blank" rel="noopener noreferrer"><div className='social-icons'><TelegramLogo className='svg-social-icon'/></div></a>
    </div>
  )
}
