import React from 'react'

export default function About() {
  return (
    <div style={{display: 'inline'}}>
      <h2>about me</h2>
      Heyo! I’m <b>Hunty</b> (or Hunter) and I’m a queer artist in the atlanta area! (she/they)<br/><br/>

      My <b>personal visual artwork</b> tends to be a reflection of the things i enjoy seeing particularly in the 
      natural world, often featuring cartoonish anthropomorphic characters, a cutesy/punkish aesthetic.<br/><br/>

      I work on a lot of physical merchandise to sell online or at in-person events, particularly at 
      <b> Furry</b> events/conventions. My philosophy with tabling is to give anyone the 
      opportunity to leave my table with a smile on their face, whether or not they have the 
      means to “purchase” something.<br/><br/>

      In 2023, I joined forces with my partner, JP aka Cocadope, to create a collaborative merch brand 
      called <b><a className='link' href="https://doubledog.studio/" target="_blank" rel="noopener noreferrer">Double Dog</a></b>. With this brand, we set out to create furry merch that speaks to our design 
      sensibilities, from alternative fashion to skate wear, that feels good for a variety of body types.<br/><br/>

      In general, I want to connect to people with my art, provide a feeling of <b>comfort 
      with oneself</b> and, through that, a freedom to <b>fully and honestly express oneself</b> within the safe 
      space of the community and <b>find joy</b> in an often brutal world. This sense of comfort, freedom, and joy 
      is something I’ve experienced through other people’s art which is what inspires me to make work of my own.<br/><br/>
      
      Feel free to contact me via any of my social media DMs or by email, <a className='link' href="mailto:huntysdoodles@gmail.com">huntysdoodles@gmail.com</a>. 
      Thanks so much for visiting my site! 💖🌷<br/><br/>
    </div>
  )
}
