import './App.css';
import { Routes, Route } from 'react-router-dom'
import Home from './components/Home'
import HowToPay from './components/HowToPay'
import Commissions from './components/Commissions';
// import Archive from './components/Archive';
import SoppingWetWednesday from './components/comm-types/SoppingWetWednesday';
import Tabling from './components/Tabling';

function App() {
  return (
    <div className="App">
      <Routes>
        {/* home - profile pic, social icons, main page links, about section */}
        <Route exact path='/' element={<Home/>}/>
        {/* commissions - selection menu of commission option pages */}
        <Route path='/commissions' element={<Commissions/>}/>
        {/* <Route path='/archive' element={<Archive/>}/> */}
        <Route path='/SoppingWetWednesday' element={<SoppingWetWednesday/>}/>
        {/* tabling */}
        <Route path='/tabling' element={<Tabling/>}/>
        {/* pay me (for remote displays) - payment processor profile pic, payment options, full shop link */}
        <Route path='/how-to-pay' element={<HowToPay/>}/>
      </Routes>
    </div>
  );
}

export default App;
