import React from 'react'

export default function SoppingWetWednesday() {
  return (
    <div style={{display: 'inline'}} className='main'>
      <h2>#SoppingWetWednesday commissions</h2><br/>
      <div>
        <b>$50 each or two for $90</b><br/>
        Printing Options:<br/>
        +$10 5x7 print,<br/>
        +$30 for 30 stickers,<br/>
        +$55 for 60 stickers,<br/>
        +$25 for 8 fridge magnets,<br/>
        (telegram sticker free upon request)<br/>
      </div>
      <div style={{display:'flex',justifyContent:'center',width:'100%',padding:'10px 0'}}><img style={{width:'300px'}} alt='grid' src="https://gdurl.com/zOId"/></div>
      <div>examples:</div>
      <div style={{display:'flex',justifyContent:'center',width:'100%',padding:'10px 0'}}><img style={{width:'300px'}} alt='grid' src="https://gdurl.com/uxlq"/></div>
      <div style={{display:'flex',justifyContent:'center',width:'100%',padding:'10px 0'}}><img style={{width:'300px'}} alt='grid' src="https://gdurl.com/HS93"/></div>
      <div style={{display:'flex',justifyContent:'center',width:'100%',padding:'10px 0'}}><img style={{width:'300px'}} alt='grid' src="https://gdurl.com/I6Gl"/></div>
      <div>using reference (include image in inquiry):</div>
      <div style={{display:'flex',justifyContent:'center',width:'100%',padding:'10px 0'}}><img style={{width:'300px'}} alt='grid' src="https://gdurl.com/5XTK"/></div>
      <div style={{display:'flex',justifyContent:'center',width:'100%',padding:'10px 0'}}><img style={{width:'300px'}} alt='grid' src="https://gdurl.com/vykk"/></div>
      {/* contact area */}
      <br/><a href='https://forms.gle/A5NGsB1RCzpzeVcq8' className='button' target='_blank' rel="noopener noreferrer">commission request form</a><br/><br/>
      message me on <a className='link' href='https://t.me/huntysdoodles' target="_blank" rel="noopener noreferrer">telegram</a>, <a className='link' href='https://twitter.com/huntysdoodles' target="_blank" rel="noopener noreferrer">twitter</a>, or <a className='link' href='https://www.instagram.com/huntysdoodles/' target="_blank" rel="noopener noreferrer">instagram</a> for questions!<br/><br/>
    </div>
  )
}