import React from 'react'
import SocialIcons from './SocialIcons'
import About from './About'
import logoOutline from '../images/logoOutline.png'

export default function Tabling() {
  return (
    <div className='main'>
      {/* profile icon */}
      <div style={{display:'flex',justifyContent:'flex-start',width:'100%',marginBottom:'10px'}}><img style={{width:'100px', border:'dashed #b4686d 5px', padding:'5px'}} alt='phone dog' src={logoOutline}/></div>
      {/* header title */}
      <h1 style={{marginTop:'5px'}}>hunty's doodles</h1>
      {/* social icons */}
      <SocialIcons />
      <hr style={{borderTop:'4px solid #b4686d', width:'100%', margin:'20px 0'}}/>
      {/* subtitle */}
      <h2>product/table examples</h2>
      <div style={{display:'flex',justifyContent:'center',width:'100%'}}><img style={{width:'310px', margin:'10px 0'}} alt='phone dog' src="https://huntysdoodles.carrd.co/assets/images/image13.jpg?v=8f932db3"/></div>
      <h2>Me! Tabling at a show in Underground Atlanta June 2022</h2>
      <div style={{display:'flex',justifyContent:'center',width:'100%'}}><img style={{width:'310px', margin:'10px 0'}} alt='phone dog' src="https://huntysdoodles.carrd.co/assets/images/image10.jpg?v=8f932db3"/></div>
      <h2>Table at Anthrocon Artist Alley 2022</h2>
      <div style={{display:'flex',justifyContent:'center',width:'100%'}}><img style={{width:'310px', margin:'10px 0'}} alt='phone dog' src="https://huntysdoodles.carrd.co/assets/images/image14.jpg?v=8f932db3"/></div>
      <div style={{display:'flex',justifyContent:'center',width:'100%'}}><img style={{width:'310px', margin:'10px 0'}} alt='phone dog' src="https://huntysdoodles.carrd.co/assets/images/image09.jpg?v=8f932db3"/></div>
      <h2>Table at Queer Art Market 2021 in Panama City Beach, FL</h2>
      <div style={{display:'flex',justifyContent:'center',width:'100%'}}><img style={{width:'310px', margin:'10px 0'}} alt='phone dog' src="https://huntysdoodles.carrd.co/assets/images/image08.jpg?v=8f932db3"/></div>
      <h2>Table at Artist Alley FWA 2022</h2>
      <div style={{display:'flex',justifyContent:'center',width:'100%'}}><img style={{width:'310px', margin:'10px 0'}} alt='phone dog' src="https://huntysdoodles.carrd.co/assets/images/image06.jpg?v=8f932db3"/></div>
      <hr style={{borderTop:'4px solid #b4686d', width:'100%', margin:'20px 0'}}/>
      {/* about me */}
      <About/>
      {/* click to view homepage */}
      <a className='link' href='./'>&lt;- view hunty's doodles homepage</a>
    </div>
  )
}
