import React from 'react'

export default function Commissions() {
  return (
    <div style={{display: 'inline'}} className='main'>
      <a className='link' href='./'>&lt;- back to homepage</a>
      <h1>commissions</h1>
      commissions are currently:<br/>
      <b>closed</b><br/><br/>
      {/* <br/>message me on <a className='link' href='https://t.me/huntysdoodles' target="_blank" rel="noopener noreferrer">telegram</a>, <a className='link' href='https://twitter.com/huntysdoodles' target="_blank" rel="noopener noreferrer">twitter</a>, or <a className='link' href='https://www.instagram.com/huntysdoodles/' target="_blank" rel="noopener noreferrer">instagram</a> for inquiries!<br/><br/> */}
      (but <a className='link' href='https://t.me/huntysdoodles' target="_blank" rel="noopener noreferrer">message me</a> if you want to be put on the waitlist)<br/><br/>
      <a className='link' href='./SoppingWetWednesday'>- SoppingWetWednesday 💦 (0/2)</a><br/>
      <b>- Telegram Stickers 📱✨ (0/1)</b><br/><br/>

      {/* <a className='link' href='./SoppingWetWednesday'>- watercolor style fashion study commissions (0/2)</a><br/>
      <a className='link' href='./SoppingWetWednesday'>- meme commissions (0/2)</a><br/>
      <a className='link' href='./SoppingWetWednesday'>- sketchpage commissions (0/1)</a><br/><br/> */}

      <a href='https://forms.gle/A5NGsB1RCzpzeVcq8' className='button' target='_blank' rel="noopener noreferrer">commission request form</a><br/><br/>
      <a className='link' href='https://t.me/huntysdoods' target="_blank" rel="noopener noreferrer">click here</a> to join my telegram channel to be notified of openings!<br/>
      <a className='link' href='https://trello.com/b/OqPedDX4/huntys-commission-queue' target="_blank" rel="noopener noreferrer">click here</a> to view commission queue and TOS<br/>
    </div>
  )
}
