import React from 'react'
import phoneDog from '../images/phoneDog.png'
import venmoLogo from '../images/venmo.png'
import cashappLogo from '../images/cashapp.png'
import paypalLogo from '../images/paypal.png'

const paymentBlock = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textDecoration: 'none',
  color: '#b4686d',
}

export default function HowToPay() {
  return (
    <div className='main'>
      {/* title */}
      <h1>hunty's doodles</h1>
      {/* subtitle */}
      <h3>how to pay for stickers:</h3>
      {/* payment options */}
      <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', margin: '20px 0 0 0'}}>
        <a href="https://venmo.com/hunterredding" style={paymentBlock} target="_blank" rel="noopener noreferrer"><img className='paymentIcons' alt='venmo' src={venmoLogo}/><h3>venmo</h3></a>
        <a href="https://cash.app/$huntysdoodles" style={paymentBlock} target="_blank" rel="noopener noreferrer"><img className='paymentIcons' alt='cashapp' src={cashappLogo}/><h3>cashapp</h3></a>
        <a href="https://paypal.me/hunterredding" style={paymentBlock} target="_blank" rel="noopener noreferrer"><img className='paymentIcons' alt='paypal' src={paypalLogo}/><h3>paypal</h3></a>
      </div>
      {/* note about what and where you purchased */}
      <div style={{textAlign:'center', margin:'20px 0'}}>put in the notes what you're paying for and where you're purchasing,<br/>e.g. "gay bug from cheba hut!"<br/>thanks!</div>
      {/* phone dog image */}
      <div style={{display:'flex',justifyContent:'center',width:'100%'}}><img style={{width:'170px'}} alt='phone dog' src={phoneDog}/></div>
      {/* full shop button */}
      <a className='button' style={{marginTop: '20px'}} href="https://huntysdoodles.square.site/s/shop" target="_blank" rel="noopener noreferrer">check out my full online shop here!</a>
      {/* thank you and social links */}
      <div style={{display:'flex', justifyContent:'center', margin:'20px 0 5px 0'}}>thank you!!!</div>
      <div style={{display:'flex', justifyContent:'space-around', padding:'0 30px'}}>
        <a href='https://twitter.com/huntysdoodles' className='fa fa-twitter' target="_blank" rel="noopener noreferrer"> </a>
        <a href='https://www.instagram.com/huntysdoodles/' className='fa fa-instagram' target="_blank" rel="noopener noreferrer"> </a>
        <a href='https://www.tumblr.com/huntysdoodles' className='fa fa-tumblr' target="_blank" rel="noopener noreferrer"> </a>
        <a href='https://bit.ly/3fHL0rF' className='fa fa-rss' alt='email newsletter' target="_blank" rel="noopener noreferrer"> </a>
      </div>
    </div>
  )
}
